import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-01";
import IntroArea from "@components/introarea/layout-2";
import IntroAreaReverse from "@components/introareareverse/layout-2";
import BoxSection from "@components/BoxSection/layout-two/layout-2";
import IntroAreaReverseThree from "@components/introareareverse/layout-3";
import IntroAreaThree from "@components/introarea/layout-3";
import ImageSection from "@components/ImageBox";
import CaseStudyFacts from "@containers/case-study/facts";

const CaseStudyCiena = ({ location, data }) => {
  const content = normalizedData(data?.page.content || []);
  const globalContent = normalizedData(data?.allGeneral.nodes || []);

  return (
    <Layout location={location}>
      <Seo title="Case study - ciena localization"
      description="Ciena enlisted Andovar’s help for a comprehensive range of video localization services, including lip-sync dubbing for hundreds of videos." 
      />
      <Header
        data={{
          ...globalContent["header"],
          ...globalContent["menu"],
        }}
      />
      <main className="site-wrapper-reveal">
        <PageHeader data={content["case-study-ciena-header"]} />
        <ImageSection data={content["case-study-ciena-image"]}/>
        <IntroArea data={content["case-study-ciena-client-body"]}/>
        <CaseStudyFacts data={content["ciena-fast-facts"]} />
        <IntroAreaReverse data={content["case-study-ciena-project-body"]} />
        <BoxSection layout={2} data={content["case-study-ciena-services"]}/>
        <IntroAreaReverseThree layout={2} data={content["case-study-ciena-language"]} />
        <IntroAreaThree data={content["case-study-ciena-volume"]}/>
        <IntroAreaReverseThree layout={2} data={content["case-study-ciena-application"]} />
      </main>
      <Footer data={{ ...data.site.siteMetadata }} />
    </Layout>
  );
};

export const query = graphql`
  query caseStudyCienabPageQuery {
    allGeneral {
      nodes {
        section
        ...HeaderOne
      }
    }
    site {
      ...Site
    }
    page(title: { eq: "case-studies" }, pageType: { eq: "innerpage" }) {
      content {
        ...PageContent
      }
    }
  }
`;

CaseStudyCiena.propTypes = {
  location: PropTypes.shape({}),
  data: PropTypes.shape({
    allGeneral: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        contact: PropTypes.shape({}),
      }),
    }),
    page: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default CaseStudyCiena;
